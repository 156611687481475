import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { TenantFrontendApi_GetTenant,TenantFrontendApi_UpdateTenant} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar";

import HomeLogo from "./Assets/HomeLogo.png"

import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';

function EditTenant(){

    const navigate = useNavigate();
    const [mRepaint,SetRepaint]=useState(false)

    const [mTenant,SetTenant]=useState("")
    const [mTenantGuid,SetTenantGuid]=useState()

    const [mCanNotMakeResellerTenant,SetCanNotMakeResellerTenant] = useState("")

    useEffect(() => {

        async function GetTenant(aTenantGuid) {
            try
            {     
                //console.log("Getting tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                SetTenant(TenantResponse.data)
            }
            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const tenantguid = ciParams.get('tenantguid')
        SetTenantGuid(tenantguid)

        GetTenant(tenantguid)
        
    }, [navigate]);

    const SetName = (aName) => {
        mTenant.Company = aName
        SetRepaint(!mRepaint)
    }

    const SetAddress1 = (aAddress1) => {
        mTenant.CompanyAddressLine1 = aAddress1
        SetRepaint(!mRepaint)
    }

    const SetAddress2 = (aAddress2) => {
        mTenant.CompanyAddressLine2 = aAddress2
        SetRepaint(!mRepaint)
    }

    const SetTenantApiSetting = (aTenantApiSetting) => {
        mTenant.ApiSettings = aTenantApiSetting
        SetRepaint(!mRepaint)
    }

    const SetPostalCode = (aPostalCode) =>{
        mTenant.CompanyPostalCode = aPostalCode
        SetRepaint(!mRepaint)
    }

    const SetCity = (aTenantCity) =>{
        mTenant.CompanyCity = aTenantCity
        SetRepaint(!mRepaint)
    }

    const SetCountry = (aTenantCountry) =>{
        mTenant.CompanyCountry = aTenantCountry
        SetRepaint(!mRepaint)
    }

    const SetPhone = (aTenantPhone) =>{
        mTenant.CompanyPhone = aTenantPhone
        SetRepaint(!mRepaint)
    }

    const SetVATNumber = (aTenantVATNumber) =>
    {
        mTenant.VATNumber = aTenantVATNumber
        SetRepaint(!mRepaint)
    }

    const SetCustomId = (aTenantCustomId) =>{
        mTenant.CustomId = aTenantCustomId
        SetRepaint(!mRepaint)
    }

    const SetIsSuperTenant = (aIsSuperTenant) =>
    {
        if(mTenant.SuperTenantGuid)
        {
            // You can not set this tenant as a Reseller tenant because it's a part of a tenant.
            SetCanNotMakeResellerTenant("Show animation")
        }
        else
        {
            mTenant.IsSuperTenant = aIsSuperTenant
            SetRepaint(!mRepaint)
        }
    }
    
    async function UpdateTenant() {
        try
        {
            const UpdateTenantResponse = await TenantFrontendApi_UpdateTenant(sessionStorage.getItem("token"),mTenantGuid,mTenant.Company,mTenant.CompanyAddressLine1,mTenant.CompanyAddressLine2,mTenant.CompanyPostalCode,mTenant.CompanyCity,mTenant.CompanyCountry,mTenant.VATNumber,mTenant.ApiSettings,mTenant.CustomId,mTenant.IsSuperTenant)
            console.log(JSON.stringify(UpdateTenantResponse))
            navigate('../tenant?tenantguid=' + mTenantGuid)
        }
        
        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function UpdateInformation(){
        return(
            UpdateTenant()
        )
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    return(
        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>
                
            <MainMenu MainMenuNumber={1} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>                
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    <tr height="60">
                                    <td
    width="100%"
    align="left"
    style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        flexWrap: 'nowrap'
    }}
>
    {/* Home Logo */}
    <a
        href="/"
        onClick={(e) => {
            if (!e.ctrlKey && !e.metaKey && e.button === 0) {
                e.preventDefault();
                navigate("/");
            }
        }}
        title="Home"
    >
        <img
            alt="Home logo"
            className="HomeLogo pointer"
            src={HomeLogo}
            style={{ cursor: "pointer" }}
        />
    </a>

    {/* Separator */}
    <span className="blue">/</span>

    {/* Tenants */}
    <a
        href="../reseller"
        onClick={(e) => {
            if (!e.ctrlKey && !e.metaKey && e.button === 0) {
                e.preventDefault();
                navigate("../reseller");
            }
        }}
        className="black bold pointer underline"
        style={{ textDecoration: "underline", cursor: "pointer" }}
    >
        {Translate("Tenants")}
    </a>

    {/* Separator */}
    <span className="blue">/</span>

    {/* Company */}
    <a
        href={`../tenant?tenantguid=${mTenantGuid}`}
        onClick={(e) => {
            if (!e.ctrlKey && !e.metaKey && e.button === 0) {
                e.preventDefault();
                navigate(`../tenant?tenantguid=${mTenantGuid}`);
            }
        }}
        className="black bold pointer underline"
        style={{ textDecoration: "underline", cursor: "pointer" }}
    >
        {mTenant.Company}
    </a>

    {/* Separator */}
    <span className="blue">/</span>

    {/* Edit (non-clickable) */}
    <span className="bold">{Translate("Edit")}</span>
</td>


                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr height="20"/>

                    
                    <tr>
                        <td className="bold fontsize30 width100 width30">{Translate("Edit tenant")}</td>
                        <td></td>
                    </tr>

                    <tr height="40"/>

                    <tr>
                        <td className="width30">
                            <BlueBar/>
                        </td>
                    </tr>           

                    <tr height="40"/>

                </tbody>
            </table>

            {/*Edit a specific tenant*/}
            <table className="usermainsettingtable" width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr height="20" ></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizelarge">{Translate("Information")}</td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Company name")} </td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000"><input value={mTenant.Company} onChange={e=>SetName(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Address")} 1 </td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000" ><input value={mTenant.CompanyAddressLine1} onChange={e=>SetAddress1(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Address")} 2 </td>
                    </tr>
                    
                    <tr height="50">
                        <td width="40"></td>
                        <td width="1000"><input value={mTenant.CompanyAddressLine2} onChange={e=>SetAddress2(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Postal code")}</td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000" ><input value={mTenant.CompanyPostalCode} onChange={e=>SetPostalCode(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("City")} </td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000" ><input value={mTenant.CompanyCity} onChange={e=>SetCity(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                   <tr height="20"/>

                   <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Country")} </td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000" ><input value={mTenant.CompanyCountry} onChange={e=>SetCountry(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                   
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Phone")} 
                            
                        </td>
                    </tr>
                   
                   <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000" ><input value={mTenant.CompanyPhone} onChange={e=>SetPhone(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("VAT number")} 
                            
                        </td>
                    </tr>

                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000" ><input value={mTenant.VATNumber} onChange={e=>SetVATNumber(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>
                     
                    <tr height="20"/>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Custom ID")} 
                            
                        </td>
                    </tr>

                    <tr height="10"/>

                    <tr height="40">
                        <td width="40"></td>
                        <td width="1000"><input value={mTenant.CustomId} onChange={e=>SetCustomId(e.target.value)} className="inputtd"></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Tenant API settings")} 
                            
                        </td>
                    </tr>
                   
                    <tr height="10"/>

                    <tr height="80">
                        <td width="40"></td>
                        <td><textarea value={mTenant.ApiSettings} onChange={e=>SetTenantApiSetting(e.target.value)} width="900" className="textareaedittenat" ></textarea></td>
                        <td></td>
                    </tr>

                    <tr height="2.5"/>

                    <tr height="80">
                        <td width="40"></td>
                        <td><input checked={mTenant.IsSuperTenant} onChange={e=>SetIsSuperTenant(e.target.checked)} type="checkbox"/>
                            <span> </span> 
                            <span className="bold">{Translate("Make this tenant a reseller.")}</span>
                            </td>
                        <td></td>
                    </tr>

                    <tr height="10"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">
                            <button className="saveusersettingsbutton bold" onClick={()=> UpdateInformation()}>{Translate("Update information")} </button> 
                        </td>
                    </tr>
                    
                    <tr height="20"/> 

                </tbody>
            </table>
            
            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>

            {/*Animation for reseller tenant.*/}
            <Modal animation={false} style={{opacity:1}} show={mCanNotMakeResellerTenant !== ""} onHide={() => SetCanNotMakeResellerTenant("")} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("This tenant is part of another tenant, so it cannot be made a reseller tenant.")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {SetCanNotMakeResellerTenant("")}}>{Translate("Ok")}</Button>
                </Modal.Footer>
            </Modal>
            
        </span>
    )
}

export default EditTenant