import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetTenant, TenantFrontendApi_GetUser} from "./TenantFrontendApi";
import { Translate } from "./Translator"

import Header from "./Header"
import MainMenu from "./MainMenu"
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import EditInformationLogo from "./Assets/EditInformationlogo.jpg"

import './Tenant.css';

function User(){

    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate();

    const [mTenantGuid, SetTenantGuid] = useState()
    const [mTenant, SetTenant] = useState("")
    const [mUser, SetUser] = useState(null)
    const [mUserGuid, SetUserGuid] = useState("") 
            
    useEffect(() => {

        async function GetUser(aUserGuid) {
            try {
                const token = sessionStorage.getItem("token");
                const UserResponse = await TenantFrontendApi_GetUser(token, aUserGuid);
                
                console.log(JSON.stringify(UserResponse));
                
                SetUser(UserResponse.data);
                SetTenantGuid(UserResponse.data.TenantGuid);
            } 
            catch (ex) {
                console.error("An error occurred while fetching user data:", ex);
        
                // Default error values
                const errorCode = ex.response?.status || 'unknown';
                const errorMessage = ex.response?.data?.Exception || ex.message || 'Unknown error';
        
                // Log specific details
                console.log(`Error Code: ${errorCode}`);
                console.log(`Error Message: ${errorMessage}`);
        
                // Navigate to error page with details
                navigate(`../error?errorcode=${errorCode}&error=user`);
            }
        }
        

        async function GetTenant(aTenantGuid) {
            try
            {
                console.log("Getting tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                console.log(JSON.stringify(TenantResponse))
                SetTenant(TenantResponse.data)
            }
            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }
        
        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const userguid = ciParams.get('userguid')
        const tenantguid = ciParams.get('tenantguid')
        SetUserGuid(userguid)
        SetTenantGuid(tenantguid)
        
        GetUser(userguid)
        if (tenantguid)
        {
            GetTenant(tenantguid)
        }
        
    }, [navigate]);

    function EditInformationButton(aUserGuid){
        return(            
            navigate('../edituser?userguid='+ aUserGuid + '&tenantguid=' + mTenantGuid)
        )
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }
    
    if (mUser == null)
    {
        return(<span>Retrieving data</span>)
    }

    else
    {
        let PageLocation = <span />;

        if (mTenant.Company)
        {
            PageLocation = (
                <tr height="60">
                    <td
                        width="100%"
                        align="left"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            flexWrap: 'nowrap'
                        }}
                    >
                        {/* Home Logo */}
                    <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}
                    title="Home"
                >
                    <img
                        alt="Home logo"
                        className="HomeLogo pointer"
                        src={HomeLogo}
                    />
                </a>

                {/* Separator */}
                <span className="blue">/</span>

                {/* Tenants */}
                <a
                    href="../reseller"
                    className="underline pointer bold black"
                    title="Tenants"
                >
                    {Translate("Tenants")}
                </a>

                {/* Separator */}
                <span className="blue">/</span>

                {/* Company */}
                <a
                    href={`../tenant?tenantguid=${mTenantGuid}`}
                    className="underline pointer bold black"
                    title={mTenant.Company}
                >
                    {mTenant.Company}
                </a>

                {/* Separator */}
                <span className="blue">/</span>

                {/* Users */}
                <a
                    href={`../tenantusers?tenantguid=${mTenantGuid}`}
                    className="underline pointer bold black"
                    title="Users"
                >
                    {Translate("Users")}
                </a>

                {/* Separator */}
                <span className="blue">/</span>

                {/* User Name */}
                <span className="bold">{mUser?.Name}</span>
            </td>
        </tr>
    );
} else {
    PageLocation = (
        <tr height="60">
            <td
                width="100%"
                align="left"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    flexWrap: 'nowrap'
                }}
            >
                {/* Home Logo */}
                <a
                    href="/"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}
                    title="Home"
                >
                    <img
                        alt="Home logo"
                        className="HomeLogo pointer"
                        src={HomeLogo}
                    />
                </a>

                {/* Separator */}
                <span className="blue">/</span>

                {/* Users */}
                <a
                    href="../users"
                    className="underline pointer bold black"
                    title="Users"
                >
                    {Translate("Users")}
                </a>

                {/* Separator */}
                <span className="blue">/</span>

                {/* User Name */}
                <span className="bold black">{mUser?.Name}</span>
            </td>
        </tr>
    );
    }


        return(
                        
            <div>

                <Header onLanguageChanged={() => languageChanged()}/>
                
                <MainMenu MainMenuNumber={1} />

                <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                    <tbody>
                        <tr className="pagetop3">
                            <td height="75" valign="bottom">
                                <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                    <tbody>
                                        {PageLocation}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                    <tbody>                
                        <tr className="pagetop4">
                            <td colSpan={4} height="75" valign="left" className="activetenants width30">
                                {mUser.Name} {Translate("Information")}
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} className="horizontalLine width30" >
                                <BlueBar/>
                            </td>
                        </tr>

                        <tr height="50"/>
                                        
                    </tbody>
                </table>

                <table align="center" className="width1200"> 
                    <tbody>
                        <tr className="pagetop6">
                            <td className="activetenants width30" colSpan={3} height="50" valign="left">
                                {Translate("Information")}
                            </td>
                            <td colSpan={2} align="right">
                                <table  className="blackbackground borderradius">
                                    <tbody>
                                        <tr>
                                            <td width="10"></td>
                                            <td><img className="width50" alt="Edit information" src={EditInformationLogo}/></td>
                                            <td><button className="noneborder blackbackground bold white pointer" onClick={()=>EditInformationButton(mUserGuid)}>{Translate("Edit information")}</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>  

                        <tr height="10"/> 

                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright" width="175">{Translate("Name")}</td>
                            <td colSpan={3} className=" companyname borderbottomright">{mUser.Name}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold borderbottomright left-padding" width="200">{Translate("Email / Sign-in")}</td>
                            <td colSpan={3} className="left-padding borderbottomright">{mUser.Email}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright">{Translate("Company")}</td>
                            <td colSpan={3} className=" companyname borderbottomright">{mUser.TenantCompany}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold borderbottomright left-padding">{Translate("License name")}</td>
                            <td colSpan={3} className=" left-padding borderbottomright">{mUser.LicenceName}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold borderleft borderbottomright">{Translate("API settings")}</td>
                            <td colSpan={3} className="left-padding borderbottomright">{mUser.ApiSettings}</td>
                        </tr>
            
                        <tr height="100"/>

                    </tbody>
                </table>

                <Footer/>
            
            </div>)
    }
}

export default User