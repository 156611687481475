import React from "react";
import { useNavigate } from 'react-router-dom';
import { Translate } from "./Translator"

import './Header.css'
import './Reseller.css'

function MainMenu(props){

    const navigate = useNavigate();

    return(
        <span>
            <table className="mainmenubar" width="100%" cellPadding={0} cellSpacing={0} align="center">
                <tbody>                
                    <tr className="pagetop2">
                        <td width="*"/>
                        <td width="1200" height="150" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    <tr height="60">
                                        <td 
                                            width="220" 
                                            className={props.MainMenuNumber === 1 ? "mainmenuitemselected" : "mainmenuitem"} 
                                            onClick={() => navigate('../reseller')}
                                        >
                                            {Translate("Tenants")}
                                        </td>
                                        <td 
                                            width="220" 
                                            className={props.MainMenuNumber === 2 ? "mainmenuitemselected" : "mainmenuitem"} 
                                            onClick={() => navigate('../users')}
                                        >
                                            {Translate("Users")}
                                        </td>
                                        <td 
                                            width="220" 
                                            className={props.MainMenuNumber === 3 ? "mainmenuitemselected" : "mainmenuitem"} 
                                            onClick={() => navigate('../licences')}
                                        >
                                            {Translate("Licenses")}
                                        </td>
                                        {sessionStorage.getItem("IsSuperTenant") !== "1" && (
                                            <td 
                                                width="300" 
                                                className={props.MainMenuNumber === 4 ? "mainmenuitemselected" : "mainmenuitem"} 
                                                onClick={() => navigate('../defaultsettings')}
                                            >
                                                {Translate("Default settings")}
                                            </td>
                                        )}
                                        <td 
                                            width="300" 
                                            className={props.MainMenuNumber === 5 ? "mainmenuitemselected" : "mainmenuitem"} 
                                            onClick={() => navigate('../support')}
                                        >
                                            {Translate("Support")}
                                        </td>
                                        <td width="*"/>
                                    </tr>
                                </tbody>
                            </table>    
                        </td>
                        <td width="*"/>
                    </tr>
                </tbody>
            </table>
        </span>
    )
}

export default MainMenu