import React,{useState,useEffect} from "react"
import { useNavigate } from "react-router-dom"

import {TenantFrontendApi_GetReseller,TenantFrontendApi_UpdateReseller} from "./TenantFrontendApi"
import { Translate } from "./Translator"
import Header from "./Header"
import MainMenu from "./MainMenu"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"

import './DefaultSettings.css';
import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';
import './Licences.css';
import BlueBar from "./BlueBar"

function DefaultSettings(){

    const navigate = useNavigate()
    const [mRepaint,SetRepaint]= useState(false)

    const [mReseller,SetReseller] = useState("")

    const [mUpdateResultPopUp, SetUpdateResultPopUp] = useState("")

    const [mSaveButtonEnable,SetButtonEnable] = useState(false)

    const [mShowResultMessage, SetShowResultMessage] = useState(false);

    useEffect(() => {

        async function GetReseller() {
            try
            { 
                //console.log("Getting reseller")
                //console.log(sessionStorage.getItem("resellerguid"))
                
                if(sessionStorage.getItem("resellerguid"))
                {
                     const Response = await TenantFrontendApi_GetReseller(sessionStorage.getItem("token"),sessionStorage.getItem("resellerguid"))
                     SetReseller(Response.data)
                }
                
                else
                {
                    SetReseller("")
                }
            }

            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetReseller()
        
    }, []);

    useEffect(() => {
        if (mUpdateResultPopUp) {
          SetShowResultMessage(true);
    
          // Hide the message after 3 seconds (3000ms)
          const timer = setTimeout(() => {
            SetShowResultMessage(false);
          }, 3000);
    
          // Clear the timer if the component unmounts or the message is updated
          return () => clearTimeout(timer);
        }
      }, [mUpdateResultPopUp]);
    

    const SetDefaultTenantApiSettings = (aDefaultTenantApiSettings) =>
    {
        SetReseller(prevReseller => ({
            ...prevReseller,
            DefaultTenantApiSettings: aDefaultTenantApiSettings
        }));
        SetRepaint(!mRepaint);
        SetButtonEnable(true)
    }
    
    const SetDefaultTenantUserApiSettings = (aDefaultTenantUserApiSettings) =>
    {
        SetReseller(prevReseller => ({
            ...prevReseller,
            DefaultTenantUserApiSettings: aDefaultTenantUserApiSettings
        }));
        SetRepaint(!mRepaint);
        SetButtonEnable(true)
    }
    
    function UpdateInformation()
    {
        UpdateReseller()
        SetUpdateResultPopUp("")
        
    }

    async function UpdateReseller() {

        try
        {
            console.log("Updating reseller default settings")
            const UpdateResellerResponse = await TenantFrontendApi_UpdateReseller(sessionStorage.getItem("token"),sessionStorage.getItem("resellerguid"),mReseller.DefaultTenantApiSettings,mReseller.DefaultTenantUserApiSettings)
            console.log(JSON.stringify(UpdateResellerResponse))
            SetUpdateResultPopUp("Succeessfully update")
            SetButtonEnable(false)
        }

        catch (ex)
        {
            //SetUpdateResultPopUp("Did not update seccessfully.")
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    let PageLocation = <span/>
        PageLocation = <tr height="60">
                            <td width="40" align="left"><a href="/" // Use the absolute path for the home page
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent default navigation behavior
                                                navigate("/"); // Use React Router navigation
                                            }}
                                            title="All tenants"
                                                ><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={()=>navigate("../")}></img></a>
                            </td>
                            <td>   
                                <span width="20" className="blue" align="center"> / </span>
                                <span className="bold" width="40" align="left" text-align="button" >{Translate("Default settings")}</span> 
                            </td>
                        </tr>


    return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={4} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    {PageLocation}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Default settings")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>                
                </tbody>
            </table>
            
            <table className="width1200 cloudctibackgroundtable" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr height="40" ></tr>
                    
                    <tr>
                        <td className=""></td>
                        <td className="bold fontsizesmall">{Translate("Default tenant API settings")}
                        </td>
                    </tr>

                    <tr height="10"/>
                    
                    <tr  height="110">
                        <td width="40"></td>
                        <td className="">
                                <textarea value={mReseller.DefaultTenantApiSettings} onChange={e=>SetDefaultTenantApiSettings(e.target.value)} className="textareadefaultsettings" ></textarea></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>

                    <tr height="30" ></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Default user API settings")}
                        </td>
                    </tr>
                        
                    <tr height="10"/>
                    
                    <tr  height="40">
                        <td width="40"></td>
                        <td width="1000" className="">
                                <textarea value={mReseller.DefaultTenantUserApiSettings} onChange={e=>SetDefaultTenantUserApiSettings(e.target.value)} className="textareadefaultsettings" ></textarea>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="30"/>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">
                            <button disabled = { mSaveButtonEnable ? false : true } className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=> UpdateInformation()}>{Translate("Save settings")}</button> 
                            <span> </span>
                            {mShowResultMessage ? <span className="green paddingleft">{Translate("Settings saved!")}</span> : <span></span>}
                        </td>
                        
                    </tr>
                
                    <tr height="30"/>
                    
                </tbody>
            </table>

            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>
            
            <Footer/>

            {/*Animation part}
            <Modal animation={false} style={{opacity:1}} show={mUpdateResultPopUp !== ""} onHide={() => SetUpdateResultPopUp("")} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{
                        (mUpdateResultPopUp === "Succeessfully update" ? Translate("The default settings have been successfully updated") : "The default settings were not successfully updated." )}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => { navigate('../reseller'); SetUpdateResultPopUp("");}}>{Translate("Homepage")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() =>SetUpdateResultPopUp("")}>{Translate("Remain on this page")}</Button>
                </Modal.Footer>
            </Modal>*/}
        </span>
    )
}

export default DefaultSettings