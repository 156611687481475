import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  TenantFrontendApi_RemoveSuperTenantFromTenant,
  TenantFrontendApi_AddTenantToSuperTenant,
  TenantFrontendApi_GetTenant,
  TenantFrontendApi_GetTenantList,
} from "./TenantFrontendApi";
import { Translate } from "./Translator";
import Header from "./Header";
import MainMenu from "./MainMenu";
import Footer from "./Footer";
import BlueBar from "./BlueBar";

import HomeLogo from "./Assets/HomeLogo.png";

import './EditUser.css';
import './EditTenant.css';
import './Licences.css';
import './Tenant.css';
import './BuyLicence.css';
import './SuperTenant.css';

function SuperTenant() {
  const navigate = useNavigate();
  const [mRepaint, SetRepaint] = useState();
  const [mTenantGuid, SetTenantGuid] = useState("");
  const [mTenant, SetTenant] = useState("");
  const [mTenantList, SetTenantList] = useState([]);
  const [error, setError] = useState(""); // New state for error handling

  useEffect(() => {
    async function GetTenantList() {
      try {
        const TenantListResponse = await TenantFrontendApi_GetTenantList(sessionStorage.getItem("token"));
        SetTenantList(TenantListResponse.data);
        RemoveSuperTenants(mTenantGuid);
      } catch (ex) {
        const error = ex.response?.data?.Exception || ex.message || "An error occurred";
        setError(error); // Set error state to display error message
        console.log("Error: " + error);
      }
    }

    async function GetTenant(aTenantGuid) {
      try {
        const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"), aTenantGuid);
        SetTenant(TenantResponse.data);
      } catch (ex) {
        const error = ex.response?.data?.Exception || ex.message || "An error occurred";
        setError(error); // Set error state to display error message
        console.log("Error: " + error);
      }
    }

    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const tenantguid = params.get('tenantguid');
    SetTenantGuid(tenantguid);

    GetTenant(tenantguid);
    GetTenantList();
  }, [mTenantGuid]);

  function languageChanged() {
    SetRepaint(!mRepaint);
  }

  function AddingThisTenants() {
    mTenantList.forEach(tenant => {
      if (tenant.SuperTenantGuid === mTenantGuid) {
        console.log("SelectedTenant: " + tenant.Company);
        AddTenantToTheSuperTenant(tenant.TenantGuid);
      } else {
        RemoveSuperTenantFromTenant(tenant.TenantGuid);
      }
    });

    navigate(`../tenant?tenantguid=${mTenantGuid}`);
  }

  async function RemoveSuperTenantFromTenant(aTenantGuid) {
    try {
      const TenantResponse = await TenantFrontendApi_RemoveSuperTenantFromTenant(sessionStorage.getItem("token"), aTenantGuid);
      console.log(TenantResponse.data);
    } catch (ex) {
      const error = ex.response?.data?.Exception || ex.message || "An error occurred";
      console.log("Error: " + error);
    }
  }

  async function AddTenantToTheSuperTenant(aTenantGuid) {
    try {
      const TenantResponse = await TenantFrontendApi_AddTenantToSuperTenant(sessionStorage.getItem("token"), aTenantGuid, mTenantGuid);
      console.log(TenantResponse.data);
    } catch (ex) {
      const error = ex.response?.data?.Exception || ex.message || "An error occurred";
      console.log("Error: " + error);
    }
  }

  const RemoveSuperTenants = (aSuperTenantGuid) => {
    SetTenantList((mTenantList) =>
      mTenantList.filter(
        tenant =>
          tenant.TenantGuid !== aSuperTenantGuid &&
          tenant.IsSuperTenant !== true &&
          (tenant.SuperTenantGuid === aSuperTenantGuid || tenant.SuperTenantGuid === "")
      )
    );
  };

  function SetThisTenantAsTenantOfSuperTenant(aCheckValue, aTenant) {
    aTenant.SuperTenantGuid = aCheckValue ? mTenantGuid : "";

    const NewTenantList = mTenantList.map(tenant =>
      tenant.TenantGuid === aTenant.TenantGuid ? aTenant : tenant
    );

    SetTenantList(NewTenantList);
  }

  const PageLocation = (
    <tr height="60">
      <td
        width="100%"
        align="left"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          flexWrap: 'nowrap',
        }}
      >
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
          title="Home"
        >
          <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} />
        </a>
        <span className="blue">/</span>
        <a href="../reseller" className="underline pointer bold black" title="Tenants">
          {Translate("Tenants")}
        </a>
        <span className="blue">/</span>
        <a href={`../tenant?tenantguid=${mTenantGuid}`} className="underline pointer bold black" title={mTenant.Company}>
          {mTenant.Company}
        </a>
        <span className="blue">/</span>
        <span className="bold black" title="Subtenants">
          {Translate("Subtenants")}
        </span>
      </td>
    </tr>
  );

  return (
    <span>
      <Header onLanguageChanged={() => languageChanged()} />
      <MainMenu MainMenuNumber={1} />
      <table width="1200" cellPadding={0} cellSpacing={0} align="center">
        <tbody>
          <tr className="pagetop3">
            <td height="75" valign="bottom">
              <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                <tbody>{PageLocation}</tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {error && (
        <div style={{ color: 'red', textAlign: 'center' }}>
          <p>{error}</p>
        </div>
      )}

      <table width="1200" cellPadding={0} cellSpacing={0} align="center">
        <tbody>
          <tr className="pagetop4">
            <td colSpan={4} height="75" valign="left" className="activetenants width30">
              {Translate("Assign or unassign the subtenants")}
            </td>
          </tr>
          <tr className="pagetop4">
            <td colSpan={4} className="horizontalLine width30">
              <BlueBar />
            </td>
          </tr>
          <tr height="50" />
        </tbody>
      </table>

      <table className="width1200" align="center">
        <tbody>
          <tr>
            <td className="bold" colSpan={2} width="500">
              {Translate("Tick the checkboxes to mark the tenants below as subtenants.")}{" "}
              <span className="blue small">{Translate("Tenants assigned to other resellers are not shown here.")}</span>
            </td>
            <td align="right">
              <button
                className="blackbackground white pointer borderradios buylicencebutton btn btn-dark"
                onClick={() => AddingThisTenants()}
              >
                {Translate("Save changes")}
              </button>
            </td>
          </tr>
          <tr height="10" />
          <tr className="tenanttableheader">
            <td colSpan={2} className="bold bordertop borderleft bluebackground white">
              {Translate("Name")}
            </td>
            <td colSpan={2} className="bold white bordertop borderleft bluebackground widthusertenantstable white borderright">
              {Translate("Assigned to this reseller")}
            </td>
          </tr>

          {mTenantList && mTenantList.length > 0 ? (
            mTenantList
              .sort((a, b) => a.Company.localeCompare(b.Company))
              .map((tenant, index) => (
                <tr className="contentenatable" key={index}>
                  <td colSpan={2} className="tenantsname borderbottomright bold leftpadding10">
                    {tenant.Company}
                  </td>
                  <td className="borderbottomright">
                    <table>
                      <tbody>
                        <tr>
                          <td width="15" />
                          <td>
                            <input
                              type="checkbox"
                              onChange={(e) => SetThisTenantAsTenantOfSuperTenant(e.target.checked, tenant)}
                              checked={tenant.SuperTenantGuid ? tenant.SuperTenantGuid === mTenantGuid : false}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td className="borderleft borderbottom borderright bold" align="center" colSpan={6}>
                {Translate("No tenant found")}
              </td>
            </tr>
          )}
          <tr height="40"/>
        </tbody>
      </table>

      <Footer />
    </span>
  );
}

export default SuperTenant;
